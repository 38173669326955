import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import globe from '../../images/lottie/globe.json'
import africaCard from '../../images/africaCard.png'




export default function ThirdSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px' }}>
                    
                        <p className="card-title" style={{fontWeight:'bold',
                        color:'#435666',
                             fontSize:windowWidth>500? 35:25}} >
                            Répartition géographique
                        </p>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:20}}>
                        Plus de 12 pays sont enregistrés sur notre plateforme, 
                        renforçant l'interconnexion. 
                        Burkina Faso, Mali, Niger, Togo, Ghana, Benin, Cote d'ivoire.
                          </p>
                          
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6">
                <img src={africaCard} style={{
                            height:windowWidth>500? 600:350,
                            width:windowWidth>500? 600:350
                        }}/>
                </div>
            </div>
        </div>
    );
}
