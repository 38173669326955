import React, { useState, useEffect } from 'react';
import {Box, Button, Stack ,Paper,Divider, CircularProgress, Tooltip, Container} from '@mui/material';
import './composants.css'; // Assurez-vous que le fichier CSS est présent
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import Lottie from 'lottie-react';
import start from '../../images/lottie/start.json'
import go from '../../images/lottie/go.json'
import people from '../../images/people.jpg'





export default function SecondSection() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
    
      window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
      };
    
      }, []);
    
    return (
        <div className="container my-5">
            <div className="row align-items-center">
                {/* Partie gauche : contenu */}
                <div className="col-md-6">
                    <div className="card border-0" style={{ padding: '10px' }}>                    
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <div style={{width:5,
                             height:100,
                             borderRadius:5,
                             backgroundColor:'#435666'}}></div>
                        <p className="card-title" style={{fontWeight:'bold', width:200, fontSize:windowWidth>500? 30:20}} >
                            Devenir Partenaire
                        </p>
                        </Stack>
                        <p className="card-text" style={{fontWeight:'', fontSize: windowWidth>500?  20:15}}>
                        Rejoignez notre réseau de partenaires et saisissez l’opportunité de 
                        faire partie d’un écosystème dynamique dédié aux paris sportifs. 
                        En devenant partenaire, vous accédez à un large public,
                         boostez votre visibilité et bénéficiez 
                        de nouvelles sources de revenus grâce à nos outils innovants dès maintenant.
                          </p>
                          
                          <Stack direction={'row'} alignItems={'center'} spacing={2}>                          
                          <Button variant="contained" 
                          href='/inscription'
                          style={{width:300, height:40,
                            backgroundColor:'#435666',}}>
                            Lancez-vous dès maintenant
                          </Button>
                          <Lottie animationData={go} loop style={{
                            height:100,
                            width:100
                        }}/>

                          </Stack>
                    </div>
                </div>
                
                {/* Partie droite : image */}
                <div className="col-md-6" align-items-center>
                <img src={people} style={{
                            height:windowWidth>500? 350:250,
                            width:windowWidth>500? 550:350,
                            borderRadius:windowWidth>500?30:20
                        }}/>
                </div>
            </div>
        </div>
    );
}
